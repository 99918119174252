import React, { useEffect, useState } from 'react'
import { Select, Form, Divider, Modal } from 'antd'
import send_message from 'common/assets/svg/send_message.svg'
import { useLazyGetLinkedinSendersQuery } from 'features/Outreach/state/api/OutreachApi'
import { post } from 'common/api/axios'
import BlurPaywall from '../../../../../../../features/intent-signals/components/paywalls/BlurPaywall'
import BlurPaywallFilters from '../../../../../../../features/intent-signals/components/paywalls/BlurPaywallFilters'
import { outreach } from 'common/constants/outreach.constants'
import styles from './LinkedinMessage.module.scss'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_IS_OUTREACHED, SET_IS_SENT } from 'features/Outreach/state/sendMessageSlice'
import { SET_DELETE_CONTACTS_SELECTED_ROWS } from 'features/contacts/state/slice/contactsSlice'
// import OutreachConnection from 'common/components/OutreachConnection/OutreachConnection'
import {
  SET_SELECTED_SENDER,
  SET_SEQUENCE_DATA,
  SET_IS_COMPARE_AB_TESTING,
} from 'common/components/OutreachCampaign/state/outreachCampaignSlice'
import MessageTabs from '../Telegram/MessageTabs'
import VariablesTags from '../Telegram/VariablesTags'
import MessageSuggestions from '../Telegram/MessageSuggestions'
import SocialConnectionModal from 'common/components/SocialConnection/SocialConnectionModal'

const { Option } = Select

const optionLabels = [
  'first_name',
  'full_name',
  'job_title',
  'company_name',
  'vertical',
  'sender_full_name',
  'sender_company',
  'sender_first_name',
  'custom_1',
  'custom_2',
]

const options = optionLabels.map((value) => ({
  value,
  label: value,
}))

interface LinkedinMessageProps {
  contactsList?: any[]
  currentSideBar?: any
  nodesData?: any
  setNodesData?: any
}

const LinkedinMessage: React.FC<LinkedinMessageProps> = ({ contactsList, currentSideBar, nodesData, setNodesData }) => {
  const labels = ['A', 'B', 'C']
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const [currentTab, setCurrentTab] = useState('A')
  const [refetcGetSenderList, { data: linkedinSenders, isLoading }] = useLazyGetLinkedinSendersQuery()
  const [compareAbTesting, setCompareAbTesting] = useState<any>(false)
  const [openContactSales, setOpenContactSales] = useState(false)
  const [openContactSalesMap, setOpenContactSalesMap] = useState(false)
  const [openLinkedinConnect, setOpenLinkedinConnect] = useState(false)
  const [checkLocalStorage, setCheckLocalStorage] = useState()
  const { selectedSender, edit_mode, enable_editing, template } = useAppSelector((state) => state.outreachCampaign)
  const indexMap: { [key: string]: number } = { A: 0, B: 1, C: 2 }
  const savedMessages = ['', '', ''].map((label, index) =>
    typeof nodesData?.steps?.[currentSideBar?.nodeId]?.data?.message === 'string'
      ? [nodesData?.steps?.[currentSideBar?.nodeId]?.data?.message]?.[index]
      : nodesData?.steps?.[currentSideBar?.nodeId]?.data?.messages?.[index] || '',
  )
  const updateNodesData = (newMessage: string, tabToRemove?: string) => {
    // Map tabKey to array index
    const index = indexMap[currentTab]

    const updatedMessages = [...savedMessages]

    if (tabToRemove) {
      const indexToRemove = indexMap[tabToRemove]
      updatedMessages.splice(indexToRemove, 1)
    } else {
      updatedMessages[index] = newMessage
    }

    const filteredMessage = updatedMessages.filter((m: string) => m && m.trim() !== '')

    setNodesData((prev: any) => {
      const updatedSteps = {
        ...prev.steps,
        [currentSideBar?.nodeId]: {
          previous_step_id: nodesData?.steps?.[currentSideBar?.nodeId]?.previous_step_id,
          type: nodesData?.steps?.[currentSideBar?.nodeId]?.type,
          data: { sender: selectedSender, messages: filteredMessage },
        },
      }

      dispatch(SET_SEQUENCE_DATA({ ...prev, steps: updatedSteps }))
      return { ...prev, steps: updatedSteps }
    })
  }

  function compareWithSavedMessages(key: any, value: any) {
    const index = labels.indexOf(key)
    const array = []
    if (index !== -1) {
      for (let i = 0; i < savedMessages.length; i++) {
        if (i !== index && value === savedMessages[i] && value !== '') {
          array.push({ equal: true, key, equalTo: labels[i] })
        }
      }
      return array
    }
    return []
  }

  const compare = (key: any, newMessage: any) => {
    const newArray = compareWithSavedMessages(key, newMessage)
    setCompareAbTesting(newArray.length > 0 ? true : false)
    dispatch(SET_IS_COMPARE_AB_TESTING(newArray.length > 0 ? true : false))
  }

  const updateSender = (nodeId: any, value: any) => {
    setNodesData((prev: any) => {
      const existingData = prev.steps[nodeId]?.data || {}
      const updatedSteps = {
        ...prev.steps,
        [nodeId]: {
          type: prev.steps[nodeId]?.type,
          previous_step_id: prev.steps[nodeId]?.previous_step_id,
          data: {
            ...existingData,
            sender: value,
          },
        },
      }

      dispatch(SET_SEQUENCE_DATA({ ...prev, steps: updatedSteps }))

      return { ...prev, steps: updatedSteps }
    })

    setNodesData((prev: any) => {
      const updatedSteps = Object.keys(prev.steps).reduce((acc: any, nodeId) => {
        const node = prev.steps[nodeId]
        if (node.type === 'send_telegram_message' || node.type === 'send_linkedin_message') {
          acc[nodeId] = {
            ...node,
            data: {
              ...node.data,
              sender: value,
            },
          }
        } else {
          acc[nodeId] = node
        }
        return acc
      }, {})

      return {
        ...prev,
        steps: updatedSteps,
      }
    })
  }

  const handleReplaceComposer = (message: string) => {
    post('/log', {
      action: 'Message Suggestions',
      data: message,
    })
    if (!edit_mode || enable_editing) {
      form.setFieldsValue({ [`message${currentTab}`]: message })
      updateNodesData(message)
    }
  }

  const handleAddToComposer = (message: string) => {
    post('/log', {
      action: 'Select Variable Campaign',
      data: message,
    })
    if (!edit_mode || enable_editing) {
      const currentMessage = form.getFieldValue(`message${currentTab}`) || savedMessages[indexMap[currentTab]]
      const cursorPosition = form.getFieldInstance(`message${currentTab}`)
      const position = cursorPosition.textarea.selectionStart

      const beforeCursor = currentMessage?.slice(0, position) || ''
      const afterCursor = currentMessage?.slice(position) || ''

      const updatedMessage = `${beforeCursor} ${message}${afterCursor}`?.trim()

      form.setFieldsValue({ [`message${currentTab}`]: updatedMessage })
      updateNodesData(updatedMessage)

      const newCursorPosition = beforeCursor.length + message.length + 1
      setTimeout(() => {
        cursorPosition.textarea.setSelectionRange(newCursorPosition, newCursorPosition)
        cursorPosition.textarea.focus()
      }, 0)
    }
  }

  // function replacePlaceholders(message: string, contact: any): string {
  //   const regex = /@(\w+)/g
  //   return message.replace(regex, (match, placeholder) => {
  //     const option = options.find((opt) => opt.value === placeholder)
  //     if (option && contact.hasOwnProperty(placeholder)) {
  //       return contact[placeholder]
  //     } else if (placeholder === 'first_name') {
  //       return contact['full_name'].split(' ')[0]
  //     }
  //     return match
  //   })
  // }

  const onFinish = async () => {
    dispatch(SET_IS_OUTREACHED(contactsList))
    dispatch(SET_IS_SENT(true))
    dispatch(SET_DELETE_CONTACTS_SELECTED_ROWS(true))

    try {
      // const values = await form.validateFields()
      // const message = values.message
      // const sender = values.sender

      // const creditsResponse = await refetchGetCredits()

      // if (!creditsResponse?.data?.response?.credits?.send_tg_message)
      //   // return popUpMessage.error(`You're out of message credits`)
      //   return

      // const localUserDetails = localStorage.getItem('user') || userDetails
      // const oUserDetails = JSON.parse(localUserDetails)

      // const contactListToSend = contactsList
      //   ?.filter(({ telegram_account }) => !!telegram_account)
      //   ?.map((contact) => {
      //     const updatedMessage = replacePlaceholders(message, contact)
      //     return {
      //       ...oUserDetails?.outreach[sender - 1],
      //       message: updatedMessage,
      //       recipient: contact?.telegram_account,
      //       contact_id: contact?.index,
      //       full_name: contact?.full_name,
      //     }
      //   })

      post('/log', {
        action: 'Press Send Message Button',
      })
    } catch (errInfo) {
      console.error('Error:', errInfo)
    }
  }

  useEffect(() => {
    const getRefetcGetSenderList = async () => {
      await refetcGetSenderList()
      form.setFieldsValue({ sender: selectedSender })
    }
    getRefetcGetSenderList()
  }, [checkLocalStorage])

  useEffect(() => {
    form.resetFields()
  }, [currentSideBar?.nodeId])

  useEffect(() => {
    setCurrentTab('A')
  }, [currentSideBar])

  return (
    <div className={styles.container} id={currentSideBar?.nodeId}>
      <div className={styles.header}>
        <img src={send_message} alt='' />
        <span>Edit Your {template === 3 ? 'LinkedIn' : 'Telegram'} Message</span>
      </div>
      <div className={styles.inputText}>
        <Form
          id={currentSideBar?.nodeId}
          key={currentSideBar?.nodeId}
          name='messageForm'
          form={form}
          layout='horizontal'
          style={{ width: '100%' }}
          onFinish={onFinish}>
          <div className={styles.senderTitle}>
            <span style={{ fontWeight: 600 }}>Sender</span>
            {currentSideBar?.nodeId === '5_1' && (
              <span
                style={{
                  color: edit_mode && !enable_editing ? '#acacac' : '#7043ff',
                  cursor: edit_mode && !enable_editing ? '' : 'pointer',
                }}
                onClick={() => {
                  ;(!edit_mode || enable_editing) && setOpenLinkedinConnect(true)
                }}>
                + Add New Account
              </span>
            )}
          </div>
          <Form.Item name='sender' style={{ marginBottom: '10px' }}>
            <Select
              style={{ width: '100%', padding: '10px 0px' }}
              mode='multiple'
              size='large'
              placeholder='Select an option'
              loading={isLoading}
              defaultValue={selectedSender}
              value={selectedSender}
              onChange={(value) => {
                // setDefaultSelect(value)
                updateSender(currentSideBar?.nodeId, value)
                dispatch(SET_SELECTED_SENDER(value))
              }}
              disabled={(edit_mode && !enable_editing) || currentSideBar?.nodeId !== '5_1'}>
              {linkedinSenders?.response.map((item: any) => (
                <Option key={item.id} value={item.id} disabled={item.cooldown}>
                  {item.email}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <span>
            Message Content
            <br />
            {!edit_mode && (
              <span style={{ color: '#a2aab8', fontSize: '13px' }}>
                Expert Advice: A/B test your outreach messages to perfect outreach efforts and increase your reach!
              </span>
            )}
          </span>

          <div style={{ position: 'relative' }}>
            <MessageTabs
              options={options}
              status={nodesData?.status}
              updateNodesData={updateNodesData}
              savedMessages={savedMessages}
              setCurrentTab={setCurrentTab}
              edit_mode={edit_mode}
              enable_editing={enable_editing}
              isOpenPhoneNumberTabel={false}
              compare={compare}
              isCompareAbTesting={compareAbTesting}
              form={form}
            />
            <Divider className={styles.divider} />
            <div className={styles.generateWithAI}>
              <VariablesTags
                onAddToComposer={handleAddToComposer}
                onReplaceMessage={handleReplaceComposer}
                currentMessage={savedMessages[indexMap[currentTab]]}
              />
            </div>
          </div>
        </Form>
      </div>
      {compareAbTesting && <div className={styles.compare_A_B_testing}>A/B testing messages can not be identical.</div>}

      <div className={styles.messageSuggestions}>
        <span style={{ paddingBottom: '10px' }}>Message Suggestions</span>
        <MessageSuggestions onAddToComposer={handleReplaceComposer} />
      </div>
      {openLinkedinConnect && (
        <SocialConnectionModal
          open={openLinkedinConnect}
          onClose={() => setOpenLinkedinConnect(false)}
          platform={'linkedin'}
          setCheckLocalStorage={setCheckLocalStorage}
        />
      )}
      <Modal
        open={openContactSales}
        onCancel={() => setOpenContactSales(false)}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}>
        <div className='flex justify-content-center'>
          <BlurPaywall
            addon={'This'}
            component={[]}
            onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
            customClass='contactSalesNoMargin'
          />
        </div>
      </Modal>
      <Modal
        open={openContactSalesMap}
        onCancel={() => setOpenContactSalesMap(false)}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        width={600}
        bodyStyle={{
          height: 350,
        }}>
        <div className='flex justify-content-center'>
          <BlurPaywallFilters
            addon={'replies_tracking'}
            component={[]}
            onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
            customClass='contactSalesNoMargin'
            type=''
          />
        </div>
      </Modal>
    </div>
  )
}

export default LinkedinMessage
