import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import HubspotInitScreen from './SalesforceInitScreen'
import SalesforceExportContacts from './SalesforceExportContacts'
import SalesforceExportCompanies from './SalesforceExportCompanies'
import { post } from 'common/api/axios'
import { useGetAlreadyTackedCreditsMutation } from 'features/contacts/state/api/ContactsApi'
import { useAppSelector } from 'state'
import { useLazyGetCreditsQuery, useLazyGetPointsStoreQuery } from 'features/settings/state/api/SettingsApi'

interface HubspotModalProps {
  open: boolean
  onClose: () => void
  selectedContacts: any
  isCompany: boolean
  selectedCompanies: any
}

const HubspotModal: React.FC<HubspotModalProps> = ({
  open,
  onClose,
  selectedContacts,
  isCompany,
  selectedCompanies,
}) => {
  const [activeComponent, setActiveComponent] = useState<string | null>('init')
  const path = window.location.pathname
  const { selected_contacts } = useAppSelector((state) => state.contacts)

  const { selected_contacts: selected_contacts_in_outreach } = useAppSelector((state) => state.myContacts)

  const userIds = path === '/exposedContacts' ? selected_contacts_in_outreach : selected_contacts

  const [companyIds, setCompanyIds] = useState([])
  const [refetchTackedCredits] = useGetAlreadyTackedCreditsMutation()
  const [telegramCredits, setTelegramCredits] = useState([])
  const [emailCredits, setEmailCredits] = useState([])
  const { selected_companies } = useAppSelector((state) =>
    path === '/exposedContacts' ? state.myContactsCompanies : state?.companies,
  )

  const [userCredits, setUserCredits] = useState<any>()
  const [userPoints, setUserPoints] = useState<any>()

  const [refetchGetCredits] = useLazyGetCreditsQuery()
  const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()

  const getCompanyIds = async () => {
    try {
      if (userIds?.length > 0) {
        const newData = await post('/contacts/getCompanyIdsFromContacts', { contact_id: userIds })
        setCompanyIds(newData.data)
      }
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const creditsResponse = await refetchGetCredits()
      setUserCredits(creditsResponse?.data?.response?.credits)

      const points_store = await refetchGetPointsStore()
      setUserPoints({
        points: points_store?.data?.response?.points,
        pointsStore: points_store?.data?.response?.pointsStore,
      })
    }
    getCompanyIds()
    fetchData().catch(console.error)
  }, [open])

  useEffect(() => {
    const fetchData = async () => {
      const result = await refetchTackedCredits({
        contacts: userIds,
      })
      if (result) {
        setTelegramCredits(result?.data?.response?.telegram)
        setEmailCredits(result?.data?.response?.email)
      }
    }

    !isCompany && fetchData().catch(console.error)
  }, [open])

  const handleOpenComponent = (componentName: string) => {
    setActiveComponent(componentName)
  }

  const goBackToInitScreen = () => {
    setActiveComponent('init')
  }

  const minWidth =
    activeComponent === 'init' || activeComponent === 'ExportContacts' || activeComponent === 'ExportCompanies'
      ? 600
      : 850
  const maxWidth = activeComponent === 'ExportContacts' ? 650 : 800

  const onCloseModal = () => {
    onClose()
    setActiveComponent('init')
  }

  return (
    <>
      <Modal
        className='modalStyleNewDeal'
        centered
        open={open}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        onCancel={() => {
          setActiveComponent('init')
          onClose()
        }}
        width={'fit-content'}
        style={{ minWidth: minWidth, maxWidth: maxWidth }}>
        {activeComponent === 'init' ? (
          <HubspotInitScreen onOpenComponent={handleOpenComponent} isCompany={isCompany} />
        ) : (
          <>
            {activeComponent === 'ExportContacts' && userPoints && (
              <SalesforceExportContacts
                onBack={goBackToInitScreen}
                onCloseModal={onCloseModal}
                selectedContacts={userIds}
                telegramCredits={telegramCredits}
                emailCredits={emailCredits}
                userCredits={userCredits}
                userPoints={userPoints}
              />
            )}
            {activeComponent === 'ExportCompanies' && userPoints && (
              <SalesforceExportCompanies
                onBack={goBackToInitScreen}
                companyIds={isCompany ? selected_companies : companyIds}
                isCompany={isCompany}
                onCloseModal={onCloseModal}
                userCredits={userCredits}
                userPoints={userPoints}
              />
            )}
          </>
        )}
      </Modal>
    </>
  )
}
export default HubspotModal
