import React, { useEffect, useState } from 'react'
import { Button, Input, Modal, List, Dropdown, message } from 'antd'
import { PlusOutlined, DownOutlined, UpOutlined, FileTextOutlined, EllipsisOutlined } from '@ant-design/icons'
import {
  useLazyAddSaveFiltersQuery,
  useLazyDeleteSaveFiltersQuery,
  useLazyGetSaveFiltersQuery,
} from 'features/Outreach/state/api/OutreachApi'
import listsFilter from 'common/assets/svg/listsFilter.svg'
import styles from './SavedFilters.module.scss'

interface SaveFiltersComponentProps {
  params: any
  handleSelect: (item: any) => void
  resetFilters: () => void
  keywords: string[]
  conditionedKeywords: string[]
}

interface SavedFilter {
  id: string
  filter_name: string
  filter_module: string
  filter: any
}

const SaveFiltersComponent = ({
  params,
  handleSelect,
  resetFilters,
  keywords,
  conditionedKeywords,
}: SaveFiltersComponentProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [filterName, setFilterName] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedFilterId, setSelectedFilterId] = useState<string | null>(null) // State to track selected item

  // Hook for API queries
  const [getSaveFilters, { data }] = useLazyGetSaveFiltersQuery()
  const [addSaveFilter] = useLazyAddSaveFiltersQuery()
  const [deleteSaveFilter] = useLazyDeleteSaveFiltersQuery()

  // Function to handle opening the modal
  const showModal = () => {
    setIsModalVisible(true)
  }

  // Function to handle closing the modal
  const handleCancel = () => {
    setIsModalVisible(false)
    setFilterName('')
  }

  const handleOnSelect = (item: SavedFilter) => {
    setSelectedFilterId(item.id) // Set the selected filter ID
    handleSelect(item.filter)
  }

  const handleOnReset = () => {
    resetFilters()
    setSelectedFilterId(null) // Reset the selected filter
  }

  // Function to save the filter
  const saveFilter = async () => {
    if (filterName) {
      setIsLoading(true)
      const data = {
        id: '',
        filter_name: filterName,
        filter: params,
        filter_module: 'campaign/engagement_hub',
      }

      try {
        // Call API to save the filter
        await addSaveFilter(data).unwrap()
        await getSaveFilters() // Fetch saved filters after adding a new one
        setFilterName('')
        setIsModalVisible(false)
        setIsExpanded(true)
        setIsLoading(false)
        message.success('Filter saved successfully!')
      } catch (error) {
        setIsLoading(false)
        message.error('Failed to save filter. Please try again.')
      }
    }
  }

  // Function to handle deletion with confirmation modal
  const handleDelete = async (item: SavedFilter) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this filter?',
      content: `${item.filter_name}`,
      okText: 'Yes',
      okButtonProps: {
        style: { backgroundColor: '#7043ff', borderColor: '#7043ff', color: '#fff' }, // Custom button color
      },
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await deleteSaveFilter({ id: item.id }).unwrap()
          await getSaveFilters() // Fetch saved filters after deleting one
          message.success('Filter removed successfully!')
        } catch (error) {
          message.error('Failed to remove filter. Please try again.')
        }
      },
    })
  }

  // Function to toggle expand/collapse
  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const getMenuItems = (item: SavedFilter) => [
    {
      key: 'delete',
      label: 'Remove Filter',
      onClick: () => handleDelete(item),
    },
  ]

  // Fetch saved filters from API on component mount
  useEffect(() => {
    getSaveFilters()
  }, [getSaveFilters])

  // Safely cast the data to SavedFilter[]
  const savedFilters = (data as SavedFilter[]) || []

  return (
    <div className={styles['save-filters-container']}>
      <Button
        type='dashed'
        icon={<PlusOutlined />}
        onClick={showModal}
        disabled={!keywords.length && !conditionedKeywords.length}
        className={styles['save-filters-button']}>
        Save as Filter
      </Button>

      <Modal
        title='Save Current Filter'
        open={isModalVisible}
        onOk={saveFilter}
        onCancel={handleCancel}
        okText='Save'
        cancelText='Cancel'
        confirmLoading={isLoading} // Add loading state to the save button
      >
        <Input placeholder='Enter filter name' value={filterName} onChange={(e) => setFilterName(e.target.value)} />
      </Modal>

      <div className={styles['save-filters-header']} onClick={toggleExpand}>
        <div className={styles['save-filters-title']}>
          <img src={listsFilter} className={styles['save-filters-icon']} alt='' />
          <span>{`Saved Filters (${savedFilters.length})`}</span>
        </div>
        {isExpanded ? <UpOutlined /> : <DownOutlined />}
      </div>

      {isExpanded && (
        <List
          bordered={false}
          dataSource={savedFilters}
          style={{
            backgroundColor: savedFilters.length > 0 ? '#fafafa' : '#ffff',
            padding: '10px',
            borderRadius: '8px',
          }}
          renderItem={(item: SavedFilter) => (
            <List.Item
              onClick={() => handleOnSelect(item)}
              className={`${styles['saved-filter-item']} ${
                item.id === selectedFilterId ? styles['selected-item'] : ''
              }`} // Apply selected style
            >
              <div>
                <FileTextOutlined className={styles['saved-filter-icon']} />
                {item.filter_name}
              </div>
              <Dropdown menu={{ items: getMenuItems(item) }} trigger={['click']} className={styles['more-options']}>
                <EllipsisOutlined
                  style={{ marginLeft: 'auto', cursor: 'pointer' }}
                  onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to List.Item
                />
              </Dropdown>
            </List.Item>
          )}
          className={styles['saved-filters-list']}
        />
      )}
      <div className={styles['modal-footer']}>
        {(keywords.length > 0 || conditionedKeywords.length > 0) && (
          <div
            onClick={handleOnReset}
            style={{
              fontSize: '14px',
              color: '#7043ff',
              cursor: 'pointer',
            }}>
            Reset Filter
          </div>
        )}
      </div>
    </div>
  )
}

export default SaveFiltersComponent
