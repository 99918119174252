import { useEffect, useMemo, useState } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Alert, Button, message, Tabs, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { isArray } from 'lodash'
import classNames from 'classnames'

import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import { useAuth } from 'common/hooks/useAuth.hooks'

import SalesforceUnlinkModal from '../Components/SalesforceMapping/SalesforceUnlinkModal'
import { SelectRow } from '../Components/SelectRow'
import useIntegrations from '../Hooks/useIntegrations'
import styles from './Salesforce.module.scss'

import warning_icon from 'common/assets/svg/warning_icon.svg'
import salesforceLogo from 'common/assets/svg/salesforce_logo.svg'

interface SalesforceProps {
  setCurrentDetailSocial: React.Dispatch<React.SetStateAction<string>>
  resetKeysStates: any
}

const Salesforce: React.FC<SalesforceProps> = ({ setCurrentDetailSocial, resetKeysStates }) => {
  const { useSalseforce } = useIntegrations()
  const {
    refetchAddSalesFieldMapping,
    salesforceIntegrationList,
    handleAddItemsToMappingList,
    handleOptionSelect,
    salesforceProps,
    deleteRowFieldMapping,
    isLoading,
    newItems,
    isFetchingAddSalesforce,
  } = useSalseforce()

  const { userDetails } = useAuth()
  const { email } = userDetails
  const [isOpenUnlink, setIsOpenUnlink] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate()
  const { handleDisconnect } = useIntegrations()
  const [warnings, setWarnings] = useState<{ [key: string]: boolean }>({})
  const [activeTab, setActiveTab] = useState('contacts')

  const handleWarningUpdate = (rowId: string, hasWarning: boolean) => {
    setWarnings((prevWarnings) => ({ ...prevWarnings, [rowId]: hasWarning }))
  }
  const isWarning = useMemo(() => Object.values(warnings).some((warning) => warning), [warnings])

  useEffect(() => {
    const getAccountHubspotDetail = () => {}
    getAccountHubspotDetail()
  }, [])

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'The Salesforce integration sync has completed successfully',
      className: 'custom-class',
      style: {
        marginTop: '90vh',
      },
    })
  }

  const handleDeleteRow = (rowId: string) => {
    setWarnings((prev) => {
      const { [rowId]: _, ...newWarning } = prev
      return newWarning
    })

    deleteRowFieldMapping(rowId)
  }

  const selectedHubspot = salesforceIntegrationList.map((f) => f.to.value)
  const customItems = newItems.filter((t: any) => {
    return t.label.toLowerCase() === activeTab
  })
  return (
    <div className={classNames(styles['AppIntegration'], 'AppIntegration')}>
      <div className={styles['AppIntegration__header']}>
        <div className={styles['AppIntegration__header__left']}>
          <img src={salesforceLogo} alt='salesforce-logo' />
          <Typography>Salesforce</Typography>
        </div>

        <div className={styles['AppIntegration__header__right']}>
          <Button className={styles['AppIntegration__header__right__button']} onClick={() => setIsOpenUnlink(true)}>
            Disconnect account
          </Button>
        </div>
      </div>

      {isWarning && (
        <div className={styles['AppIntegration__warning']}>
          <Alert
            message='There are mandatory fields that must be filled in before you can start syncing. Please complete these fields.'
            type='error'
            showIcon
            icon={<img src={warning_icon} alt='warning' />}
            banner
            style={{
              padding: '16px',
              borderRadius: '8px',
              background: '#FF5C5C1A',
              color: '#5E6C84',
              fontSize: '16px',
            }}
          />
        </div>
      )}

      <div className={styles['AppIntegration__content']}>
        <div className={classNames(styles['AppIntegration__content--box'], styles['AppIntegration__content__left'])}>
          <Typography className={styles['AppIntegration__content--box__title']}>Mapping</Typography>

          <Tabs
            defaultActiveKey='contacts'
            centered
            className='integrationtab'
            onChange={(tab) => {
              setActiveTab(tab)
            }}
            style={{ width: '100%' }}>
            <Tabs.TabPane tab={<>Contact</>} key='contacts' style={{ position: 'relative' }}>
              <div className={styles['AppIntegration__content__left__mandatory']}>
                <Typography>Mandatory</Typography>
                <div className={styles['AppIntegration__content__left__list']}>
                  {isLoading ? (
                    <LoadingBox />
                  ) : (
                    <div>
                      <div className={styles.headerList}>
                        <span>{'Convrt'}</span>
                        <span>{'Salesforce'}</span>
                      </div>
                      {salesforceIntegrationList
                        .filter((item) => item.group === 'Contacts')
                        .map((item, index) => (
                          <SelectRow
                            key={index}
                            items={isArray(newItems) ? newItems.filter((item) => item.label === 'Contacts') : []}
                            onOptionSelect={handleOptionSelect}
                            row={item}
                            hubspot={salesforceProps?.response?.filter((item: any) => item.label === 'Contacts')}
                            showDeleteRow={false}
                            deleteRow={handleDeleteRow}
                            updateWarning={handleWarningUpdate}
                            enableMap={true}
                            selectedHubspot={selectedHubspot}
                          />
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={<>Company</>} key='company'>
              <div className={styles['AppIntegration__content__left__mandatory']}>
                <Typography>Mandatory</Typography>

                <div className={styles['AppIntegration__content__left__list']}>
                  {isLoading ? (
                    <LoadingBox />
                  ) : (
                    <div>
                      <div className={styles.headerList}>
                        <span>{'Convrt'}</span>
                        <span>{'Salesforce'}</span>
                      </div>
                      {salesforceIntegrationList
                        .filter((item) => item.group === 'Companies')
                        .map((item, index) => (
                          <SelectRow
                            key={index}
                            items={isArray(newItems) ? newItems.filter((item) => item.label === 'Company') : []}
                            onOptionSelect={handleOptionSelect}
                            row={item}
                            hubspot={salesforceProps?.response?.filter((item: any) => item.label === 'Companies')}
                            showDeleteRow={false}
                            deleteRow={handleDeleteRow}
                            updateWarning={handleWarningUpdate}
                            enableMap
                            selectedHubspot={selectedHubspot}
                          />
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
          <div className={styles['AppIntegration__content__left__custom']}>
            <Typography>Custom</Typography>
            <div className={styles['AppIntegration__content__left__list']}>
              <div className={styles.headerList}>
                <span>{'Convrt'}</span>
                <span>{'Salesforce'}</span>
              </div>
              {salesforceIntegrationList
                .filter((item) => item.group?.includes(`custom:${activeTab}`))
                .map((item, index) => (
                  <SelectRow
                    key={index}
                    items={isArray(newItems) ? customItems : []}
                    onOptionSelect={handleOptionSelect}
                    row={item}
                    hubspot={salesforceProps?.response}
                    showDeleteRow={true}
                    deleteRow={handleDeleteRow}
                    updateWarning={handleWarningUpdate}
                    enableMap
                  />
                ))}
            </div>
            <Button
              type='text'
              icon={<PlusCircleOutlined />}
              className={styles['AppIntegration__header__right__text']}
              onClick={() => handleAddItemsToMappingList(`custom:${activeTab}`)}>
              Add another
            </Button>
          </div>
          <div className={styles['AppIntegration__content__rightBtn']}>
            <Button
              type='primary'
              disabled={isLoading || isFetchingAddSalesforce || isWarning}
              onClick={
                !isLoading
                  ? () => {
                      refetchAddSalesFieldMapping({
                        salesforceFieldMapping: salesforceIntegrationList,
                        isSaveFieldMapping: true,
                      })
                        .unwrap()
                        .then(() => {
                          success()
                        })
                    }
                  : () => {}
              }>
              Save
            </Button>
          </div>
        </div>
      </div>
      {isOpenUnlink && (
        <SalesforceUnlinkModal
          open={isOpenUnlink}
          onCancel={() => setIsOpenUnlink(false)}
          onSubmit={async () => {
            const res = await handleDisconnect('Salesforce')
            if (res) {
              message.success('Disconect Salesforce successfully')
              resetKeysStates('salesforce')
              navigate('/settings', { state: { action: 'unlink' } })
              setCurrentDetailSocial('')
            } else {
              message.error('Unlink failed')
            }
          }}
          email={email}
        />
      )}
      {contextHolder}
    </div>
  )
}

export default Salesforce
