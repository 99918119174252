import React, { useEffect, useState } from 'react'
import { Input, Layout, Select, Tag } from 'antd'
import styles from './GroupsFilters.module.scss'
import { UndoOutlined } from '@ant-design/icons'

const { Sider } = Layout
const { Option } = Select

const siderStyle: React.CSSProperties = {
  background: 'white',
}

interface GroupsFiltersProps {
  onFilterChange: ({ keywords, operator, conditionedKeywords }: any) => any
  keywords: string[]
  setKeywords: React.Dispatch<React.SetStateAction<string[]>>
  conditionedKeywords: string[]
  setConditionedKeywords: React.Dispatch<React.SetStateAction<string[]>>
  operator: string
  setOperator: React.Dispatch<React.SetStateAction<string>>
}

const GroupsFilters: React.FC<GroupsFiltersProps> = ({
  onFilterChange,
  keywords,
  setKeywords,
  conditionedKeywords,
  setConditionedKeywords,
  operator,
  setOperator,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [conditionedInputValue, setConditionedInputValue] = useState('')

  const handleAddKeyword = () => {
    const trimmedValue = inputValue.trim()
    if (trimmedValue && !keywords.includes(trimmedValue)) {
      setKeywords((prevKeywords) => [...prevKeywords, trimmedValue])
      setInputValue('')
    }
  }

  const handleAddConditionedKeyword = () => {
    const trimmedValue = conditionedInputValue.trim()
    if (trimmedValue && !conditionedKeywords.includes(trimmedValue)) {
      setConditionedKeywords((prevExcludeKeywords) => [...prevExcludeKeywords, trimmedValue])
      setConditionedInputValue('')
    }
  }

  const handleResetKeywords = () => {
    setKeywords([])
  }

  const handleResetConditionedKeywords = () => {
    setConditionedKeywords([])
  }

  useEffect(() => {
    onFilterChange({ keywords, operator, conditionedKeywords })
  }, [keywords, operator, conditionedKeywords])

  return (
    <Sider width={'100%'} style={siderStyle}>
      <div className={styles.container}>
        <div className={styles.input}>
          <Input
            placeholder='Enter keywords'
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onPressEnter={handleAddKeyword}
            suffix={keywords.length > 0 && <UndoOutlined style={{ cursor: 'pointer' }} onClick={handleResetKeywords} />}
          />
          {keywords?.length > 0 && (
            <div className={styles.chips} style={{ marginTop: '16px' }}>
              {keywords.map((keyword, index) => (
                <Tag
                  className={`${styles.tags} groupsFilterTags`}
                  key={keyword}
                  closable
                  onClose={() => setKeywords((prevKeywords) => prevKeywords.filter((k) => k !== keyword))}>
                  {keyword}
                </Tag>
              ))}
            </div>
          )}
        </div>
        <Select value={operator} onChange={setOperator} style={{ width: '100%' }}>
          <Option value='AND'>AND</Option>
          <Option value='AND NOT'>AND NOT</Option>
        </Select>
        <Input
          placeholder='Enter keywords'
          value={conditionedInputValue}
          onChange={(e) => setConditionedInputValue(e.target.value)}
          onPressEnter={handleAddConditionedKeyword}
          suffix={
            conditionedKeywords.length > 0 && (
              <UndoOutlined style={{ cursor: 'pointer' }} onClick={handleResetConditionedKeywords} />
            )
          }
        />
        <div className={styles.chips}>
          {conditionedKeywords.map((keyword, index) => (
            <Tag
              className={`${styles.tags} ${
                operator === 'AND NOT' ? `${styles.redTags} groupsFilterRedTags` : 'groupsFilterTags'
              }`}
              key={keyword}
              closable
              onClose={() =>
                setConditionedKeywords((prevExcludeKeywords) => prevExcludeKeywords.filter((k) => k !== keyword))
              }>
              {keyword}
            </Tag>
          ))}
        </div>
      </div>
    </Sider>
  )
}

export default GroupsFilters
