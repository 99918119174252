import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/api/baseQueryWithValidation'

export const SettingsApi = createApi({
  reducerPath: 'SettingsApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    generateOneTimeToken: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/settings/generateOneTimeToken`,
        body: {
          userId: params.userId,
        },
      }),
    }),
    getConvrt: builder.query({
      query: () => ({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/user/getConvrt`,
      }),
    }),
    getCredits: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings`,
      }),
    }),
    getSubscriptionSettings: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getSubscriptionSettings`,
      }),
    }),
    getPaymentDetails: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getPaymentDetails`,
      }),
    }),
    applyDiscountCode: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/settings/applyDiscountCode`,
        body: {
          product: params.product,
          discountCode: params.discountCode,
        },
      }),
    }),
    changeStripePaymentMethod: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/settings/changeStripePaymentMethod`,
        body: params,
      }),
    }),
    getUsersList: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getUsersList`,
      }),
    }),
    disableUser: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/settings/disableUser`,
        body: params,
      }),
    }),
    archiveUser: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/settings/archiveUser`,
        body: params,
      }),
    }),
    resetUserPassword: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/settings/resetUserPassword`,
        body: params,
      }),
    }),
    makeCompanyAdmin: builder.query({
      query: (params: any) => ({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/settings/makeCompanyAdmin`,
        body: params,
      }),
    }),
    upgradePlan: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/upgradePlan`,
        method: 'POST',
        body: { plan: params.plan, billing_cycle: params.billing_cycle },
      }),
    }),
    buyMoreSeats: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/buyMoreSeats`,
        method: 'POST',
        body: {
          plan: params.plan,
          billing_cycle: params.billing_cycle,
          quantity: params.quantity,
          convrtPoints: params.convrtPoints,
          managedAccount: params.managedAccount,
        },
      }),
    }),
    buyMorePoints: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/buyMorePoints`,
        method: 'POST',
        body: {
          convrtPoints: params.convrtPoints,
        },
      }),
    }),
    assignSeats: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/assignSeats`,
        method: 'POST',
        body: params,
      }),
    }),
    getUnassignedSeats: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getUnassignedSeats`,
        method: 'GET',
      }),
    }),
    getAssignedSeats: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getAssignedSeats`,
        method: 'GET',
      }),
    }),
    getUnassignedPoints: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getUnassignedPoints`,
        method: 'GET',
      }),
    }),
    getAssignedPoints: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getAssignedPoints`,
        method: 'GET',
      }),
    }),
    assignPoints: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/assignPoints`,
        method: 'POST',
        body: params,
      }),
    }),
    getIsStripeCustomer: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getIsStripeCustomer`,
        method: 'GET',
      }),
    }),
    getHandleSpothub: builder.query({
      query: (key: string) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/hubspot`,
        method: 'POST',
        body: { key: key },
      }),
    }),
    getCheckHubspotKey: builder.query({
      query: (key: string) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/checkhubspotkey`,
        method: 'POST',
        body: { key: key },
      }),
    }),
    getHubspotProperties: builder.query({
      query: (key?: string) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/hubspot/getProperties`,
        method: 'POST',
        body: { key: key },
      }),
    }),
    addHubspotFieldMapping: builder.query({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/hubspot/fieldMapping`,
        method: 'POST',
        body: { hubspotFieldMapping: params.hubspotFieldMapping, isSaveFieldMapping: params.isSaveFieldMapping },
      }),
    }),
    getHubspotFieldMapping: builder.query({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/hubspot/getFieldMapping`,
      }),
    }),
    unlinkHubSpotAccount: builder.mutation({
      query: (arg?: any) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/hubspot/unlink`,
        method: 'DELETE',
      }),
    }),
    getLoginHubSpot: builder.query({
      query: (key: string) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/hubspotOAuth`,
        method: 'POST',
        body: { key: key },
      }),
    }),
    getSubscriptionDate: builder.query({
      query: (key: string) => ({
        url: `${process.env.REACT_APP_API_URL}/user/clientsRenewals`,
        method: 'GET',
      }),
    }),
    getPointsStore: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/pointsStore`,
        method: 'GET',
      }),
    }),
    changeUserPlan: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/changeUserPlan`,
        method: 'POST',
        body: {
          plan: params.plan,
          billing_cycle: params.billing_cycle,
          usersToUpgrade: params.usersToUpgrade,
        },
      }),
    }),
    getAllUsers: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getAllUsers`,
        method: 'GET',
      }),
    }),
    updateOutreachDetails: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/updateOutreachDetails`,
        method: 'POST',
        body: {
          value: params.value,
          type: params.type,
          key: params.key,
        },
      }),
    }),
    getStripeInvoiceByCustomer: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getStripeInvoiceByCustomer`,
        method: 'GET',
      }),
    }),
    getTelegramAccountInfo: builder.query({
      query: (params: any) => ({
        url: `https://connectplus-api.convrt.io/getAccountInfo?telegram_number=${params.telegram_number}`,
        method: 'GET',
      }),
    }),
    getCampaignsForAccountsService: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/settings/getCampaignsForAccounts`,
        method: 'GET',
      }),
    }),
    getAccountHubspotDetail: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/hubspot/accountDetail`,
        method: 'GET',
      }),
    }),
    getHubspotSetting: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/hubspot/getSettings`,
        method: 'GET',
      }),
    }),
    addHubspotSetting: builder.mutation({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/hubspot/saveSettings`,
        method: 'POST',
        body: {
          user_id: params.user_id,
          campaign_id: params.campaign_id,
          action_type: params.action_type,
          hubspot_action_type: params.hubspot_action_type,
          is_sync: params.is_sync,
          update_contact_chat_activity: params.update_contact_chat_activity,
          contact_owner_id: params.contact_owner_id,
        },
      }),
    }),
    getHubspotDealOwner: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/outreach/getDealOwner`,
        method: 'GET',
      }),
    }),
    getSalesforceFieldMapping: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/salesforce/getFieldMapping`,
      }),
    }),
    getSalesforceProperties: builder.query({
      query: (key?: string) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/salesforce/getProperties`,
        method: 'GET',
      }),
    }),
    addSalesforceFieldMapping: builder.query({
      query: (params?: any) => ({
        url: `${process.env.REACT_APP_API_URL}/contacts/salesforce/fieldMapping`,
        method: 'POST',
        body: { salesforceFieldMapping: params.salesforceFieldMapping, isSaveFieldMapping: params.isSaveFieldMapping },
      }),
    }),
    getSalesforceAuthorizationUrl: builder.query({
      query: (params: any) => ({
        url: `${
          process.env.REACT_APP_API_URL
        }/admin/settings/salesforce/getAuthorizationUrl?codeChallenge=${encodeURIComponent(params.codeChallenge)}`,
        method: 'GET',
      }),
    }),
    getSalesforceAccessToken: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/salesforce/getAccessToken?codeVerifier=${params.codeVerifier}&authCode=${params.authCode}`,
        method: 'GET',
      }),
    }),
    redirectSalesforceOAuth: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/salesforce/oauth_redirect?code=${encodeURIComponent(
          params.code,
        )}`,
        method: 'GET',
      }),
    }),
    getSalesforceKeys: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/salesforce/getKeys`,
        method: 'GET',
      }),
    }),
    disconnectSalesforce: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/admin/settings/salesforce/disconnect`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useLazyGenerateOneTimeTokenQuery,
  useLazyGetConvrtQuery,
  useLazyGetCreditsQuery,
  useLazyGetSubscriptionSettingsQuery,
  useLazyGetPaymentDetailsQuery,
  useLazyApplyDiscountCodeQuery,
  useLazyChangeStripePaymentMethodQuery,
  useLazyGetUsersListQuery,
  useLazyDisableUserQuery,
  useLazyArchiveUserQuery,
  useLazyResetUserPasswordQuery,
  useLazyMakeCompanyAdminQuery,
  useLazyUpgradePlanQuery,
  useLazyBuyMoreSeatsQuery,
  useLazyBuyMorePointsQuery,
  useLazyAssignSeatsQuery,
  useLazyGetUnassignedSeatsQuery,
  useLazyGetAssignedSeatsQuery,
  useLazyGetUnassignedPointsQuery,
  useLazyGetAssignedPointsQuery,
  useLazyAssignPointsQuery,
  useLazyGetIsStripeCustomerQuery,
  useLazyGetHandleSpothubQuery,
  useLazyGetCheckHubspotKeyQuery,
  useLazyGetHubspotPropertiesQuery,
  useLazyAddHubspotFieldMappingQuery,
  useLazyGetHubspotFieldMappingQuery,
  useLazyGetLoginHubSpotQuery,
  useLazyGetSubscriptionDateQuery,
  useLazyGetPointsStoreQuery,
  useLazyChangeUserPlanQuery,
  useLazyGetAllUsersQuery,
  useLazyUpdateOutreachDetailsQuery,
  useLazyGetStripeInvoiceByCustomerQuery,
  useLazyGetTelegramAccountInfoQuery,
  useLazyGetCampaignsForAccountsServiceQuery,
  useLazyGetAccountHubspotDetailQuery,
  useLazyGetHubspotSettingQuery,
  useAddHubspotSettingMutation,
  useLazyGetHubspotDealOwnerQuery,
  useUnlinkHubSpotAccountMutation,
  useLazyGetSalesforceAccessTokenQuery,
  useLazyGetSalesforceAuthorizationUrlQuery,
  useLazyRedirectSalesforceOAuthQuery,
  useLazyGetSalesforceKeysQuery,
  useLazyDisconnectSalesforceQuery,
  useLazyGetSalesforcePropertiesQuery,
  useLazyGetSalesforceFieldMappingQuery,
  useLazyAddSalesforceFieldMappingQuery,
} = SettingsApi as any
