import { useEffect, useState } from 'react'
import { message } from 'antd'
import {
  useLazyDisconnectSalesforceQuery,
  useLazyGetSalesforceAuthorizationUrlQuery,
  useLazyAddSalesforceFieldMappingQuery,
  useLazyGetSalesforceFieldMappingQuery,
  useLazyGetSalesforcePropertiesQuery,
} from 'features/settings/state/api/SettingsApi'
import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { LocalStorageKeys } from 'common/constants/localstorage.constants'

const useIntegrations = () => {
  const [getSalesforceAuthorizationUrl] = useLazyGetSalesforceAuthorizationUrlQuery()
  const [disconnectSalesforce] = useLazyDisconnectSalesforceQuery()

  const generateRandomString = (length: number) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz'
    let randomString = ''
    const array = new Uint8Array(length)
    window.crypto.getRandomValues(array)
    for (let i = 0; i < length; i++) {
      randomString += chars.charAt(array[i] % chars.length)
    }
    return randomString
  }

  const base64URLEncode = (buffer: any) => {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
  }

  const generateCodeChallenge = async (codeVerifier: any) => {
    const encoder = new TextEncoder()
    const data = encoder.encode(codeVerifier)
    const digest = await crypto.subtle.digest('SHA-256', data)
    return base64URLEncode(digest)
  }

  const handleConnect = async (platform: string) => {
    switch (platform) {
      case 'Salesforce':
        const codeVerifier = generateRandomString(64)
        const codeChallenge = await generateCodeChallenge(codeVerifier)
        localStorage.setItem('salesforce_code_verifier', codeVerifier)
        const { data } = await getSalesforceAuthorizationUrl({ codeVerifier, codeChallenge })
        window.location.href = data?.url
        break
      case 'Salesforce_blank':
        const _codeVerifier = generateRandomString(64)
        const _codeChallenge = await generateCodeChallenge(_codeVerifier)
        localStorage.setItem('salesforce_code_verifier', _codeVerifier)
        const res = await getSalesforceAuthorizationUrl({
          codeVerifier: _codeVerifier,
          codeChallenge: _codeChallenge,
        })
        window.open(res.data?.url, '_blank')
        break
      default:
        break
    }
  }

  const handleDisconnect = async (platform: string) => {
    switch (platform) {
      case 'Salesforce':
        const { data: salesforceDisconnectData } = await disconnectSalesforce()
        localStorage.removeItem('salesforce_code_verifier')
        localStorage.removeItem('salesforce_access_token')
        return salesforceDisconnectData?.disconnected
      default:
        return false
    }
  }

  type salesforce_integration_list_type = { caption: string; id: number; from?: any; to?: any; group?: string }[]
  let numOfSelectRows: number = 0

  const items: {
    label: string
    options: {
      dataSource?: string
      label: string
      value: string
      type: string
      parent_label?: string
    }[]
  }[] = [
    {
      label: 'Contacts',
      options: [
        {
          label: 'Name',
          value: 'full_name',
          type: 'string',
          parent_label: 'Contacts',
        },
        {
          label: 'Company',
          value: 'company_name',
          type: 'string',
          parent_label: 'Contacts',
        },
        {
          label: 'Location',
          value: 'city',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Job title',
          value: 'job_title',
          type: 'string',
          parent_label: 'Contacts',
        },
        {
          label: 'Email',
          value: 'email',
          type: 'string',
          parent_label: 'Contacts',
        },
        {
          label: 'Phone Number',
          value: 'phone_numbers',
          type: 'number',
          parent_label: 'Contacts',
        },
        {
          label: 'Telegram',
          value: 'telegram_account',
          type: 'string',
          parent_label: 'Contacts',
        },
        {
          label: 'Company Domain',
          value: 'company_domain',
          type: 'string',
          parent_label: 'Contacts',
        },
        {
          label: 'Linkedin page',
          value: 'linkedin',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Twitter',
          value: 'twitter',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Seniority',
          value: 'seniority',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Source (Convrt)',
          value: 'source_convrt_contacts',
          type: 'string',
          parent_label: 'Contacts',
        },
      ],
    },
    {
      label: 'Company',
      options: [
        {
          label: 'Company',
          value: 'name',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Description',
          value: 'description',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Company URL',
          value: 'url',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Revenue',
          value: 'raw_yearly_revenue',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Location',
          value: 'city',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Vertical',
          value: 'vertical',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Active wallets',
          value: 'monthly_active_users',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'TVL',
          value: 'tvl',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Token name',
          value: 'token_name',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Smart contracts',
          value: 'sc_count',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Total funding',
          value: 'funding_total_usd',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'State',
          value: 'state',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Domain',
          value: 'domain',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Phone',
          value: 'contact_phone',
          type: 'string',
          parent_label: 'Companies',
        },
        {
          label: 'Source (Convrt)',
          value: 'source_convrt_company',
          type: 'string',
          parent_label: 'Companies',
        },
      ],
    },
    {
      label: 'Campaign',
      options: [
        {
          label: 'Name',
          value: 'name',
          type: 'string',
          parent_label: 'Campaign',
        },
        {
          label: 'Platform',
          value: 'platform',
          type: 'string',
          parent_label: 'Campaign',
        },
      ],
    },
  ]

  const defaultIntegrationList = {
    '1': {
      from: { value: 'full_name', label: 'Name' },
      to: { value: 'FirstName', label: 'First Name' },
      caption: 'row-1',
      id: 1,
      group: 'Contacts',
    },
    '2': {
      from: { value: 'email', label: 'Email' },
      to: { value: 'Email', label: 'Email' },
      caption: 'row-2',
      id: 2,
      group: 'Contacts',
    },
    '3': {
      from: { value: 'phone_numbers', label: 'Phone Number' },
      to: { value: 'Phone', label: 'Business Phone' },
      caption: 'row-3',
      id: 3,
      group: 'Contacts',
    },
    '4': {
      from: { value: 'job_title', label: 'Job title' },
      to: { value: 'Title', label: 'Title' },
      caption: 'row-4',
      id: 4,
      group: 'Contacts',
    },
    '5': {
      from: { value: 'telegram_account', label: 'Telegram' },
      to: { value: 'OtherPhone', label: 'Other Phone' },
      caption: 'row-5',
      id: 5,
      group: 'Contacts',
    },
    '6': {
      from: { value: 'city', label: 'Location' },
      to: { value: 'MailingCity', label: 'Mailing City' },
      caption: 'row-6',
      id: 6,
      group: 'Contacts',
    },
    '7': {
      from: { value: 'company_name', label: 'Company' },
      to: { value: 'Department', label: 'Department' },
      caption: 'row-7',
      id: 7,
      group: 'Contacts',
    },
    '8': {
      from: { value: 'linkedin', label: 'Linkedin page' },
      to: { value: 'Title', label: 'Title' },
      caption: 'row-8',
      id: 8,
      group: 'Contacts',
    },
    '9': {
      from: { value: 'twitter', label: 'Twitter' },
      to: { value: 'AssistantName', label: `Assistant's Name` },
      caption: 'row-9',
      id: 9,
      group: 'Contacts',
    },
    '10': {
      from: { value: 'seniority', label: 'Seniority' },
      to: { value: 'MailingCountry', label: 'Mailing Country' },
      caption: 'row-10',
      id: 10,
      group: 'Contacts',
    },
    '11': {
      from: { value: 'company_domain', label: 'Company Domain' },
      to: { value: 'Fax', label: 'Business Fax' },
      caption: 'row-11',
      id: 11,
      group: 'Contacts',
    },
    '12': {
      from: { value: 'name', label: 'Company' },
      to: { value: 'Name', label: 'Account Name' },
      caption: 'row-12',
      id: 12,
      group: 'Companies',
    },
    '13': {
      from: { value: 'city', label: 'Location' },
      to: { value: 'BillingCity', label: 'Billing City' },
      caption: 'row-13',
      id: 13,
      group: 'Companies',
    },
    '14': {
      from: { value: 'state', label: 'State' },
      to: { value: 'BillingState', label: 'Billing State/Province' },
      caption: 'row-14',
      id: 14,
      group: 'Companies',
    },
    '15': {
      from: { value: 'domain', label: 'Domain' },
      to: { value: 'Website', label: 'Website' },
      caption: 'row-15',
      id: 15,
      group: 'Companies',
    },
  }

  let selectedOptions: any = {}

  const useSalseforce = () => {
    // eslint-disable-next-line
    const [user, setUser] = useLocalStorage(LocalStorageKeys.HUBSPOT_ACCESS_TOKEN, null)
    const [refetchAddSalesFieldMapping, { isFetching: isFetchingAddSalesforce }] =
      useLazyAddSalesforceFieldMappingQuery()
    const [refetchGetSalesforceFieldMapping] = useLazyGetSalesforceFieldMappingQuery()
    const [isLoading, setIsLoading] = useState(true)
    // eslint-disable-next-line
    const [existKey, setExistKey] = useState(false)
    const { userDetails } = useAuth()
    const [salesforceIntegrationList, setSalesforceIntegrationList] = useState<salesforce_integration_list_type>([])
    const [refetchGetSalesforceProperties, { data: salesforceProps }] = useLazyGetSalesforcePropertiesQuery()

    let objetcFieldMapping: any = {}

    const handleOptionSelect = (fieldMapping: any, row_id: number, type: string, group: string) => {
      if (objetcFieldMapping[row_id] === undefined) {
        objetcFieldMapping[row_id] = { from: '', to: '', caption: `row-${row_id}`, id: row_id, group: group }
      }
      objetcFieldMapping[row_id][type] = fieldMapping
      selectedOptions = { ...selectedOptions, ...objetcFieldMapping }

      const newList = salesforceIntegrationList.map((data: any) => {
        let row = { ...data }
        if (row.id === row_id) {
          row[type] = objetcFieldMapping[row_id][type]
        }
        return row
      })
      setSalesforceIntegrationList(newList)
    }
    const handleAddItemsToMappingList = (group = '') => {
      numOfSelectRows++
      setSalesforceIntegrationList([
        ...salesforceIntegrationList,
        {
          caption: `row-${numOfSelectRows}`,
          id: numOfSelectRows,
          from: { label: 'Select', value: `select-from-${numOfSelectRows}` },
          to: { label: 'Select', value: `select-to-${numOfSelectRows}` },
          group,
        },
      ])
    }

    const deleteRowFieldMapping = (row_id: string) => {
      selectedOptions = salesforceIntegrationList.filter((item: any) => {
        return item.id !== row_id
      })

      setSalesforceIntegrationList((salesforceIntegrationList) =>
        salesforceIntegrationList.filter((item: any) => item.id !== row_id),
      )
    }

    useEffect(() => {
      const fetchSalesforceData = async () => {
        try {
          const tokenSalesforce = localStorage.getItem('salesforce_access_token')
          const res = await refetchGetSalesforceProperties(tokenSalesforce)
          setExistKey(res.isSuccess)
        } catch (e) {
          message.error('Error when fetching salesforce properties!')
          console.error('ERROR: ', e)
        }
      }

      const tokenSalesforce = localStorage.getItem('hubspot_access_token')
      if (tokenSalesforce && tokenSalesforce !== 'null') {
        fetchSalesforceData().catch(console.error)
      }
    }, [])

    const fetchSaleforceFieldMapping = async () => {
      try {
        const result = await refetchGetSalesforceFieldMapping()
        if (result?.data?.response?.length) {
          selectedOptions = result.data.response
          const index = Object.keys(selectedOptions).pop() || 0
          numOfSelectRows = selectedOptions[index].id
          let list = Object.values(result.data.response)
          setSalesforceIntegrationList(list.map((i: any) => ({ ...i })))
        } else {
          selectedOptions = defaultIntegrationList
          const index = Object.keys(selectedOptions).pop() || 0
          numOfSelectRows = selectedOptions[index].id
          let list = Object.values(defaultIntegrationList)
          setSalesforceIntegrationList(list.map((i: any) => ({ ...i })))
          setSalesforceIntegrationList(list)
        }
        setIsLoading(false)
      } catch (e) {
        console.error('ERROR: ', e)
      }
    }

    const fetchDefaultSaleforceFieldMapping = () => {
      selectedOptions = defaultIntegrationList
      const index = Object.keys(selectedOptions).pop() || 0
      numOfSelectRows = selectedOptions[index].id
      let list = Object.values(defaultIntegrationList)
      return list.map((i: any) => ({ ...i }))
    }
    // This function removes mapping options based on the active addons.
    const filterItems = (items: any, addons: any) => {
      if (!addons?.funding) {
        items.forEach((category: any) => {
          category.options = category.options.filter(
            (option: any) =>
              !['funding_total_usd', 'last_funding_date', 'last_funding_amount_usd'].includes(option.value),
          )
        })
      }
      if (!addons?.onChain) {
        items.forEach((category: any) => {
          category.options = category.options.filter(
            (option: any) =>
              ![
                'chains_repartition',
                'tvl',
                'avg_deployment_diff_in_sec',
                'sc_count',
                'monthly_volume',
                'transactions_count',
                'token_name',
                'token_symbol',
                'total_users',
                'monthly_active_users',
                'monthly_new_users',
                'whales',
                'chains',
              ].includes(option.value),
          )
        })
      }
      if (!addons?.web2metrics) {
        items.forEach((category: any) => {
          category.options = category.options.filter(
            (option: any) => !['top_countries', 'website_traffic'].includes(option.value),
          )
        })
      }
      if (!addons?.revenue) {
        items.forEach((category: any) => {
          category.options = category.options.filter(
            (option: any) => !['raw_yearly_revenue', 'revenue_source'].includes(option.value),
          )
        })
      }
      return items
    }

    useEffect(() => {
      if (existKey) {
        fetchSaleforceFieldMapping()
      }
    }, [existKey])

    const addons = userDetails.addons
    const newItems = filterItems(items, addons)
    // filterItems(items, addons)

    return {
      existKey,
      fetchSaleforceFieldMapping,
      handleAddItemsToMappingList,
      refetchAddSalesFieldMapping,
      refetchGetSalesforceFieldMapping,
      salesforceIntegrationList,
      newItems,
      handleOptionSelect,
      salesforceProps,
      deleteRowFieldMapping,
      isLoading,
      isFetchingAddSalesforce,
      setExistKey,
      setUser,
      fetchDefaultSaleforceFieldMapping,
    }
  }

  return {
    handleConnect,
    handleDisconnect,
    useSalseforce,
  }
}

export default useIntegrations
