import { forEach, isEmpty, isNull, trim } from 'lodash'
import { ParseResult } from 'papaparse'

const isValidDomain = (domain: any) => {
  const trimDomain = trim(domain)
  if (isEmpty(trimDomain)) {
    return false
  }

  const domainRegex = /^(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2,}))$/
  return domainRegex.test(trimDomain)
}

const isValidUrl = (value: string) => {
  // eslint-disable-next-line
  const pattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

  return !!pattern.test(value)
}

const formatDomain = (domain: string) => {
  if (!domain) return

  if (!isValidUrl(domain)) return domain

  const newDomainWithHttp = domain.startsWith('http') ? domain : 'https://' + domain
  const parsedUrl = new URL(newDomainWithHttp)

  let hostname = parsedUrl.hostname
  return hostname.replace(/^www\./, '')
}

const calculatePointCampaign = (messages: string[], nodeId: string, optionLabels: string[], followUps: number) => {
  let score = 0
  let totalLabelCount = 0
  let scoreDetails = [
    {
      action: 'Message detected',
      points: 50,
    },
    {
      action: 'A/B testing variation.',
      points: 10,
    },
    {
      action: 'Use personalization variable.',
      points: 5,
    },
  ]
  const templates = ['A', 'B', 'C']
  let templatesWithContent: string[] = []
  const maxLength = followUps === 2 ? 500 : 300
  if (!messages || !Array.isArray(messages)) {
    return {
      total: score,
      details: scoreDetails,
    }
  }

  const hasNonEmptyMessage = messages.some((msg: string) => msg?.trim()?.length > 0)
  if (hasNonEmptyMessage) {
    score += 50
  }

  messages.forEach((msg: string, index: number) => {
    if (msg?.trim()?.length > 0) {
      if (index !== 0) {
        templatesWithContent.push(templates[index])
      }
      optionLabels.forEach((label) => {
        if (msg.includes(`@${label}`)) {
          totalLabelCount++
        }
      })
    }
  })

  if (nodeId === '1') {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    const urlMatches = messages.find((msg) => msg.match(urlRegex))
    const longMgs = messages.find((msg) => msg?.trim()?.length > maxLength)
    scoreDetails.push(
      {
        action: `Link on first message.`,
        points: -50,
      },
      {
        action: `Long message.`,
        points: -50,
      },
    )
    if (urlMatches) {
      score -= 50
    }
    if (longMgs) {
      score -= 50
    }
  }
  if (templatesWithContent.length > 0) {
    score += templatesWithContent.length * 10
  }

  if (totalLabelCount > 0) {
    score += totalLabelCount * 5
  }

  return {
    total: score,
    details: scoreDetails,
  }
}

const validateResultPapaParse = async (result: ParseResult<any>) => {
  let formatedData: any = []
  // let validRowData: any = []
  let unvalidRowData: any = []
  let isValidAmountOfRow = true

  if (result.data) {
    const { data } = result

    //REMOVE EMPTY COLUMN AND ROW
    forEach(data, (rowData) => {
      const isEmptyRow = rowData?.every((value: any) => isNull(value) || isEmpty(value) || !value)
      if (!isEmptyRow) {
        formatedData.push(rowData)
      }
    })

    if (formatedData.length > 10000) {
      isValidAmountOfRow = false

      return { data: {}, isValidAmountOfRow }
    }
  }

  return {
    data: {
      totalRowData: [...formatedData.slice(1)],
      rowData: [...formatedData.slice(1)],
      unvalidRowData,
      fieldArray: formatedData[0],
    },
    isValidAmountOfRow,
  }
}

const numberFormatter = new Intl.NumberFormat('en-US')

export { isValidDomain, formatDomain, validateResultPapaParse, numberFormatter, calculatePointCampaign }
