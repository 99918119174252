import React, { useState } from 'react'
import automation from 'common/assets/svg/automation.svg'
import hubspot from 'common/assets/svg/hubspot.svg'
import salesforce from 'common/assets/svg/salesforce.svg'
// import newDeal from 'common/assets/svg/new_deal.svg'
// import BlurPaywall from 'features/intent-signals/components/paywalls/BlurPaywall'

import { Button, Dropdown, Alert, Space } from 'antd'
import type { MenuProps } from 'antd'
import styles from './ContactsActions.module.scss'

import { useLazyGetLoginHubSpotQuery } from '../../../features/settings/state/api/SettingsApi' // '../settings/state/api/SettingsApi'

import { UpOutlined } from '@ant-design/icons'
import HubspotModal from 'features/Outreach/components/Hubspot/HubspotModal'
import useIntegrations from 'features/settings/Pages/Hooks/useIntegrations'
interface ContactsActionsProps {
  contact: any
}

const ContactsActions: React.FC<ContactsActionsProps> = ({ contact }) => {
  const [refetchLoginHubSpot] = useLazyGetLoginHubSpotQuery()
  const [actions, setActions] = useState(false)
  const [openHubspotModal, setOpenHubspotModal] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showAlertSalesforce, setShowAlertSalesforce] = useState(false)
  const hubspot_access_token = localStorage.getItem('hubspot_access_token')
  const salesforce_access_token = localStorage.getItem('salesforce_access_token')
  const disable = hubspot_access_token === 'null' || hubspot_access_token === undefined
  const disableSalesforce = salesforce_access_token === 'null' || salesforce_access_token === undefined
  const { handleConnect } = useIntegrations()
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className={styles.actions}>
          <img src={hubspot} alt='' />
          <span>Hubspot Enrichment</span>
        </div>
      ),
      onClick: () => {
        if (hubspot_access_token === 'null' || !hubspot_access_token) {
          setActions(false)
          setShowAlert(true)
        } else {
          setOpenHubspotModal(true)
          setActions(false)
          setShowAlert(false)
        }
      },
      disabled: disable,
    },
    {
      key: '2',
      label: (
        <div className={styles.actions}>
          <img src={salesforce} alt='' />
          <span>Salesforce Enrichment</span>
        </div>
      ),
      onClick: () => {
        if (salesforce_access_token === 'null' || !salesforce_access_token) {
          setActions(false)
          setShowAlertSalesforce(true)
        } else {
          setOpenHubspotModal(true)
          setActions(false)
          setShowAlertSalesforce(false)
        }
      },
      disabled: disableSalesforce,
    },
    {
      key: '3',
      label: (
        <div className={styles.actions}>
          <img src={automation} alt='' />
          <span>Add to Automation</span>
        </div>
      ),
      disabled: true,
    },
  ]

  const handleLoginHubSpot = async () => {
    const result = await refetchLoginHubSpot()

    const newWindow = window.open(result.data.response, '_blank')

    if (newWindow) {
      newWindow.focus()
    }
  }

  return (
    <>
      <Dropdown menu={{ items }} placement='top' trigger={['click']} arrow>
        <div
          className={styles.actionsButton}
          onClick={() => {
            setActions(!actions)
          }}>
          <span>Actions</span>
          <UpOutlined style={{ color: 'black', fontSize: '11px', paddingLeft: '5px' }} rotate={actions ? 0 : 180} />
        </div>
      </Dropdown>

      {hubspot_access_token && (
        <HubspotModal
          open={openHubspotModal}
          onClose={() => {
            setOpenHubspotModal(false)
          }}
          selectedContacts={[contact?.response]}
          selectedCompanies={[]}
          isCompany={false}
        />
        // <HubspotNewDeal />
      )}

      {showAlert && (
        <Alert
          style={{ position: 'absolute', top: '25px', left: ' 0px' }}
          message='Enrich HubSpot with Convrt'
          description='To enrich contacts/companies and create/update deals, connecting your HubSpot account with Convrt is required'
          type='info'
          action={
            <Space direction='vertical'>
              <Button
                size='small'
                type='primary'
                onClick={() => {
                  handleLoginHubSpot()
                  setShowAlert(false)
                }}>
                Connect
              </Button>
            </Space>
          }
          closable
          onClose={() => setShowAlert(false)}
        />
      )}
      {showAlertSalesforce && (
        <Alert
          style={{ position: 'absolute', top: '25px', left: ' 0px' }}
          message='Enrich Salesforce with Convrt'
          description='To enrich contacts/companies and create/update deals, connecting your Salesforce account with Convrt is required'
          type='info'
          action={
            <Space direction='vertical'>
              <Button
                size='small'
                type='primary'
                onClick={() => {
                  handleConnect('Salesforce_blank')
                  setShowAlertSalesforce(false)
                }}>
                Connect
              </Button>
            </Space>
          }
          closable
          onClose={() => setShowAlertSalesforce(false)}
        />
      )}
    </>
  )
}

export default ContactsActions

// import React, { useState } from 'react'
// import { ConfigProvider, Input, Menu, Modal } from 'antd'
// import automation from 'common/assets/svg/automation.svg'
// import hubspot from 'common/assets/svg/hubspot.svg'
// import newDeal from 'common/assets/svg/new_deal.svg'
// import SubMenu from 'antd/es/menu/SubMenu'
// import BlurPaywall from 'features/intent-signals/components/paywalls/BlurPaywall'

// const ContactsActions = () => {
//   const [lockedFeature, setLockedFeature] = useState(false)
//   return (
//     <div>
//       <ConfigProvider
//         theme={{
//           components: {
//             Menu: {
//               colorItemBg: 'white',
//               // colorItemBgHover: '#7043ff',
//               fontSize: 13,
//               colorItemBgHover: 'white',
//               colorPrimaryHover: 'white',
//               colorSubItemBg: 'white',
//               borderRadius: 8,
//               borderRadiusOuter: 8,
//               colorActiveBarBorderSize: 0,
//             },
//           },
//         }}>
//         <Menu style={{ width: 'fit-content', borderRadius: '8px' }} mode='inline' inlineIndent={6}>
//           <SubMenu key='sub1' title='Actions'>
//             <SubMenu key='sub2' title='Hubspot Enrichment' icon={<img src={hubspot} alt='' />}>
//               <Menu.Item
//                 onClick={() => {
//                   setLockedFeature(true)
//                 }}
//                 key='1'
//                 icon={<img src={newDeal} alt='' />}>
//                 Create a New Deal Per Company
//               </Menu.Item>
//             </SubMenu>
//             <Menu.Item
//               onClick={() => {
//                 setLockedFeature(true)
//               }}
//               key='4'
//               icon={<img src={automation} alt='' />}>
//               Add to Automation
//             </Menu.Item>
//           </SubMenu>
//         </Menu>
//       </ConfigProvider>
//       <Modal
//         open={lockedFeature}
//         onCancel={() => setLockedFeature(false)}
//         okButtonProps={{
//           className: 'hidden',
//         }}
//         cancelButtonProps={{
//           className: 'hidden',
//         }}>
//         <div className='flex justify-content-center'>
//           <BlurPaywall addon={'This option'} component={[]} customClass='contactSalesNoMargin' />
//         </div>
//       </Modal>
//     </div>
//   )
// }

// export default ContactsActions
