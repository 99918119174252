import { Button, Modal } from 'antd'
import salesforceLogo from 'common/assets/svg/salesforce_logo.svg'
import styles from './SalesforceUnlinkModal.module.scss'

interface SalesforceUnlinkModalProps {
  open: boolean
  onCancel: () => void
  onSubmit: () => void
  email?: string
}
const SalesforceUnlinkModal: React.FC<SalesforceUnlinkModalProps> = ({ open, onCancel, onSubmit, email }) => {
  return (
    <Modal
      rootClassName={styles.unlinkModalCustomized}
      centered
      width={380}
      open={open}
      title=''
      onCancel={onCancel}
      footer={[
        <div className={styles.footer} key='footer'>
          <Button className={styles.yesBtn} key='yes' onClick={onSubmit} type='text'>
            Yes, disconnect account
          </Button>
          <Button className={styles.cancelBtn} key='cancel' onClick={onCancel} type='text'>
            Cancel
          </Button>
        </div>,
      ]}>
      <div className={styles.contentCustomized}>
        <img src={salesforceLogo} alt='hubspot-logo' />
        <label>{`Disconnect Salesforce account ${email}`}</label>
        <span>
          {`Are you sure you want to disconnect the Salesforce account ${email}? This action will stop syncing for
          this account and remove the connection with Salesforce.`}
        </span>
      </div>
    </Modal>
  )
}
export default SalesforceUnlinkModal
